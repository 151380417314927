<template>
  <div class="bg-gray-200 rounded p-4 mt-2 mb-6">
    <template v-if="prompt">
      <div class="flex flex-col items-start">
        <h2 class="font-semibold text-gray-600 text-sm uppercase">Prompt</h2>
        <p class="text-xl font-sans font-semibold text-gray-900 my-1">
          {{ prompt }}
        </p>
        <template v-if="!file">
          <button
            class="animation-focus-default flex items-center text-gray-600 text-base font-sans font-semibold focus:outline-none"
            @click="clearPrompt">
            <span class="mr-2">
              <ArrowLeft class="arrow-left-icon"></ArrowLeft>
            </span>
            Change Prompt
          </button>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col items-start">
        <h2 class="font-semibold text-gray-600 text-sm uppercase mb-1">Choose a prompt</h2>
        <button class="text-lg font-semibold text-primary my-1 text-left"
                v-for="option in promptOptions"
                v-bind:key="option.prompt"
                @click="selectPrompt(option.prompt)">{{ option.prompt }}</button>
      </div>
    </template>
  </div>
</template>

<script>
import ArrowLeft from '@/assets/arrow_left.svg';

export default {
  name: 'VideoPromptSelection',
  components: {
    ArrowLeft,
  },
  props: {
    /**
     * selected prompt
     */
    prompt: {
      type: String,
    },
    /**
     * array of prompt options
     */
    promptOptions: {
      type: Array,
    },
    /**
     * function to select prompt from array of options
     */
    selectPrompt: {
      type: Function,
    },
    /**
     * reset selection back to null
     */
    clearPrompt: {
      type: Function,
    },
    /**
     * recording has been made but not submitted
     */
    file: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.arrow-left-icon path {
  fill: #807C77;
}
</style>
