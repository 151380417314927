<template>
  <div class="mt-3 sm:mb-2 lg:mb-0 w-full">
    <template v-if="profile.isOnMyList">
      <Button
        class="w-full border-primary"
        :disabled="loading"
        type="secondary" color-custom
        @onClick="removeFromList(group.slug, profile.slug)"
      >
        <span :class="{'text-primary': !isColorDefault(), 'text-orange-400': isColorDefault()}">
          Remove from your list
        </span>
      </Button>
    </template>
    <template v-else>
      <Button
        class="w-full"
        type="secondary"
        :disabled="loading"
        :loading="loading"
        @onClick="addToList(group.slug, profile.slug)"
      >
        <div class="flex justify-center items-center">
          <PlusIcon class="w-4 h-4 mr-3"></PlusIcon>
          <span class="text-orange-400">Add to your list</span>
        </div>
      </Button>
    </template>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import PlusIcon from '@/assets/icon_plus.svg';
import { mapGetters } from 'vuex';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';

export default {
  name: 'AddToYourListButton',
  components: {
    Button,
    PlusIcon,
  },
  data() {
    return {
      defaultColor: DEFAULT_COLOR,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  methods: {
    isColorDefault() {
      return this.group ? this.group.primaryColor === this.defaultColor : true;
    },
  },
  props: {
    /**
     * profile's group
     */
    group: {
      default: () => {
      },
    },
    /**
     * profile being viewed
     */
    profile: {
      default: () => {
      },
    },
    /**
     * loading state
     */
    loading: {
      default: false,
    },
    /**
     * action to call when user wants to add profile to list
     */
    addToList: {
      type: Function,
    },
    /**
     * action to call when user wants to remove profile from list
     */
    removeFromList: {
      type: Function,
    },
  },
};
</script>
