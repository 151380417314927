<template>
  <div>
      <template v-if="!isSafariMobile">
        <div class="p-3 bg-gray-200 rounded relative video-container">
          <span v-if="showRecord" @click="handleRecordClick" class="icon"><Record /></span>
          <span v-if="showPlay" @click="playPause" class="icon"><Play /></span>
          <span v-if="showStopRecord" @click="handleStopClick" class="icon"><Stop /></span>
          <span v-if="showStopPlay" @click="playPause" class="icon"><Stop /></span>
          <video ref="videoRef"
            id="video" class="video-js vjs-default-skin rounded" playsinline>
          </video>
          <button
            :class="{ 'hidden': !file }"
            class="animation-focus-default focus:outline-none flex items-center mt-3 text-gray-600 text-base font-sans font-semibold"
            @click="rerecord()">
            <span class="mr-2"><ArrowLeft class="arrow-left-icon"></ArrowLeft></span>
            Record again
          </button>
        </div>
      </template>
      <template v-else>
        <div class="bg-gray-200 rounded p-4">
          <Button
            :class="{ 'hidden': file, 'block': !file }"
            type="primary"
            @onClick="openCamera"
            class="">Record a video</Button>
          <input
            class="hidden"
            @change="onChange"
            ref="fileInput"
            type="file"
            accept="video/*"
            capture>
          <video
            ref="videoPlayer"
            class="mt-2 w-88 h-88 object-cover rounded"
            :class="{ 'hidden': !file, 'block': file }"
            controls>
            <source ref="videoSource" src=""/>
          </video>
        </div>
      </template>
      <template v-if="errorMessage">
        <ErrorBox :message="errorMessage" />
      </template>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'webrtc-adapter';
// eslint-disable-next-line no-unused-vars,import/no-extraneous-dependencies
import RecordRTC from 'recordrtc';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import ArrowLeft from '@/assets/arrow_left.svg';
import Play from '@/assets/play_recorder.svg';
import Record from '@/assets/record.svg';
import Stop from '@/assets/stop.svg';
import Button from '@/components/Button.vue';
import ErrorBox from '@/components/ErrorBox.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import videojs from 'video.js';
// eslint-disable-next-line no-unused-vars
import Recorder from 'videojs-record/dist/videojs.record';

export default {
  name: 'VideoRecorder',
  components: {
    ArrowLeft,
    Button,
    ErrorBox,
    Play,
    Record,
    Stop,
  },
  props: {
    file: {
      type: Blob,
    },
    updateRecordingFile: {
      type: Function,
    },
    isSafariMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: '',
      errorMessage: null,
      hasRecorded: false,
      showPreview: false,
      showRecord: false,
      showPlay: false,
      showStopRecord: false,
      showStopPlay: false,
      options: {
        controls: true,
        autoplay: false,
        fluid: true,
        controlBar: {
          // hide fullscreen and volume controls
          fullscreenToggle: false,
          volumePanel: false,
        },
        loop: false,
        aspectRatio: '1:1',
        bigPlayButton: false,
        type: 'video/mp4',
        plugins: {
          record: {
            pip: false,
            audio: true,
            video: {
              width: 480,
              height: 480,
            },
            debug: true,
            maxLength: 30,
            // dimensions of captured video frames
            frameWidth: 480,
            frameHeight: 480,
          },
        },
      },
    };
  },
  mounted() {
    if (!this.isSafariMobile) {
      this.player = videojs('#video', this.options);
      // listen for the video being played so we know which icon to show (play or stop)
      this.$el.querySelector('video')
        .addEventListener('ended', this.videoStopped, false);
      // initialize camera immediately to save user a click
      this.$el.querySelector('.vjs-icon-av-perm').click();


      // device is ready
      this.player.on('deviceReady', () => {
        this.showRecord = true;
        this.showStopRecord = false;
        this.showPlay = false;
      });

      // user clicked the record button and started recording
      this.player.on('startRecord', () => {
        this.showRecord = false;
        this.showStopRecord = true;
        this.showPlay = false;
      });

      // user completed recording and stream is available
      this.player.on('finishRecord', () => {
        this.showRecord = false;
        this.showStopRecord = false;
        this.showPlay = true;
        this.updateRecordingFile(this.player.recordedData);
      });

      // error handling
      this.player.on('error', (_, error) => {
        this.errorMessage = error;
      });

      this.player.on('deviceError', () => {
        this.errorMessage = this.player.deviceErrorCode;
      });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    rerecord() {
      this.errorMessage = null;
      this.showPlay = false;
      this.showStopPlay = false;
      this.showRecord = true;
      this.$emit('rerecord');
    },
    openCamera() {
      this.$refs.fileInput.click();
    },
    onChange() {
      this.$refs.videoSource.src = URL.createObjectURL(this.$refs.fileInput.files[0]);
      this.$refs.videoPlayer.load();
      if (this.$refs.fileInput.files[0]) {
        this.updateRecordingFile(this.$refs.fileInput.files[0]);
      } else {
        this.errorMessage = 'Please try recording again.';
        this.rerecord();
      }
    },
    handleRecordClick() {
      const recordButton = this.$el.querySelector('.vjs-record-button.vjs-control.vjs-button.vjs-icon-record-start');
      recordButton.click();
    },
    playPause() {
      const video = this.$refs.videoRef;
      if (video.paused) {
        this.showPlay = false;
        this.showStopPlay = true;
        video.play();
      } else {
        this.showPlay = true;
        this.showStopPlay = false;
        video.pause();
      }
    },
    videoStopped() {
      this.showPlay = true;
      this.showStopPlay = false;
    },
    handleStopClick() {
      const stopButton = this.$el.querySelector('.vjs-record-button.vjs-control.vjs-button.vjs-icon-record-stop');
      stopButton.click();
    },
  },
};
</script>

<style>
.icon {
  @apply absolute z-50 cursor-pointer;
  bottom: 15%;
  left: calc(50% - 40px);
}
  /*hide camera icon between it being initialized and clicked*/
  .vjs-icon-av-perm {
    visibility: hidden;
  }
  .vjs-record-button.vjs-control.vjs-button.vjs-icon-record-stop,
  .vjs-record-button.vjs-control.vjs-button.vjs-icon-record-start,
  .vjs-play-control.vjs-control.vjs-button.vjs-paused,
  .vjs-play-control.vjs-control.vjs-button.vjs-playing,
  .vjs-pip-button.vjs-control.vjs-button.vjs-icon-picture-in-picture-start {
    display: none;
  }
  .arrow-left-icon path {
    fill: #807C77;
  }
</style>
