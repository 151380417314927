<template>
  <form>
    <p class="pt-2 mb-4 text-gray-900 text-base font-normal leading-[160%]">
      Enter the email address of the person taking ownership of the account.
    </p>
    <div class="relative">
      <div class="mb-8">
        <TextInput
          id="email"
          label="Email Address"
          placeholder="Enter Email Address"
          @blur="$v.email.$touch()"
          type="email"
          v-model="email"
        />
      </div>
      <span v-if="$v.email.$dirty && !$v.email.required" class="error-message">
        This field is required.
      </span>
      <span v-else-if="$v.email.$dirty && !$v.email.email" class="error-message">
        Invalid email address entered
      </span>
      <span v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </span>
    </div>

    <div class="w-full flex justify-end pt-4">
      <Button
        class="save-btn max-w-[260px] h-10 ml-0 sm:ml-2 mb-2 sm:mb-0"
        type="primary"
        compact
        @onClick="submit"
      >
        <span class="text-sm text-white font-semibold">Transfer Account Ownership</span>
      </Button>
    </div>
  </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import { transferAccountService } from '@/services/global.service';
import { mapGetters } from 'vuex';

export default {
  name: 'TransferForm',

  components: { TextInput, Button },

  props: {
    profileId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      email: '',
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters(['group']),
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await transferAccountService(this.group.slug, this.profileId, this.email);
          this.$emit('submit', this.email);
        } catch (e) {
          const errorData = e.response.data;
          const keys = Object.keys(errorData);

          if (keys.length > 0) {
            const firstKey = keys[0];
            const [value] = errorData[firstKey];
            this.errorMessage = value;
          } else {
            console.log(e);
          }
        }
      }
    },
  },
};
</script>
