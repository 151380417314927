<template>
  <section
    ref="modal"
    class="flex w-full h-full fixed flex justify-center items-center inset-0 z-50"
    tabindex="0"
    @keydown.esc="close"
  >
    <div class="bg-black opacity-25 w-full h-full fixed z-50"></div>

    <div
      class="modal bg-gray-100 my-0 sm:my-8 absolute px-4 sm:px-10 pb-10 pt-6 flex flex-col sm:pb-14 rounded z-50 max-w-[100vw] w-full sm:max-w-4xl md:w-216 sm:min-h-0"
    >
      <button
        class="absolute top-3 right-3 sm:right-4 text-sans font-bold text-gray-900 animation-focus-default"
        type="text"
        @click="close"
      >
        <CloseIcon class="close-icon w-6 h-6"></CloseIcon>
      </button>
      <div
        class="modal-content overflow-y-auto flex lg:justify-between mt-4 lg:flex-wrap custom-scroll flex-col lg:flex-row items-center lg:items-start"
      >
        <div class="lg:flex-1 w-full lg:pr-10">
          <h1 class="font-serif text-gray-900 text-1_8xl sm:text-3xl">
            {{ file ? 'Review your video' : 'Record your video story' }}
          </h1>
          <template v-if="!file">
            <p
              :class="{ 'hidden sm:block': prompt }"
              class="text-gray-700 font-sans text-base sm:text-lg py-4"
            >
              Talk directly to your group by recording a video story or upload an existing video.
              Choose a prompt, record a short response, and add it to your profile.
            </p>
          </template>
          <VideoPromptSelection
            class="mr-3 sm:mr-7 lg:mr-0"
            :prompt="prompt"
            :promptOptions="promptOptions"
            :clearPrompt="clearPrompt"
            :selectPrompt="selectPrompt"
            :file="!!file"
          />
          <template v-if="file">
            <div class="my-4 bg-gray-200 rounded p-4">
              <h1 class="font-semibold text-gray-600 text-sm mb-4">PRIVACY</h1>
              <div class="mr-4">
                <RadioButton
                  id="radio1"
                  label="Public - Every group member can view"
                  value="public"
                  name="public"
                  v-model="privacy"
                />
              </div>
              <div class="mr-4">
                <RadioButton
                  id="radio2"
                  label="Private - Only I can view"
                  value="private"
                  name="private"
                  v-model="privacy"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="lg:flex-1 max-w-94 w-full">
          <template v-if="prompt && !prerecordedSource">
            <VideoRecorder
              :prerecordedFileSelected="prerecordedSource"
              :isSafariMobile="isSafariMobile"
              :updateRecordingFile="updateRecordingFile"
              @rerecord="
                file = null;
                prerecordedSource = null;
              "
              :file="file"
            />
          </template>
          <template v-if="prompt">
            <template v-if="prerecordedSource && file">
              <div class="relative bg-gray-200 rounded p-4 mt-2 mb-6 mr-3 sm:mr-7 lg:mr-0">
                <h2 class="font-semibold text-gray-600 text-sm uppercase">
                  Selected
                  <CloseIcon
                    @click="
                      file = null;
                      prerecordedSource = null;
                    "
                    class="animation-focus-default cursor-pointer absolute top-2 right-2 w-5 h-5"
                  ></CloseIcon>
                </h2>
                <p class="text-xl font-sans font-semibold text-gray-900 mt-1 mb-3 truncate">
                  {{ file.name }}
                </p>
                <video class="w-88 h-88 object-cover rounded m-auto" preload="metadata" controls>
                  <source :src="prerecordedSource" />
                </video>
              </div>
            </template>
            <div v-if="!file" class="bg-gray-200 rounded p-4 mt-2 mb-6">
              <input
                hidden
                id="prerecordedVideoInput"
                type="file"
                accept="video/mp4, video/*"
                @change="handlePrerecordedUpload"
              />
              <Button :disabled="!!prerecordedSource" type="primary" @onClick="clickUploadFile">
                Upload from Device
              </Button>
              <p class="text-sm italic text-grey-700 ml-auto mt-3">
                Videos should be 30 seconds or less.
              </p>
            </div>
          </template>
          <template v-if="errorMessage">
            <ErrorBox :message="errorMessage" />
          </template>
        </div>
      </div>
      <p
        :class="{ invisible: !uploadingMessage }"
        class="text-sm italic mb-4 text-grey-700 ml-auto"
      >
        {{ uploadingMessage }}
      </p>
      <div class="mt-auto ml-auto">
        <Button
          type="primary"
          :disabled="!file || isLoading"
          :loading="isLoading"
          @onClick="handleUpload"
        >
          Publish
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VideoRecorder from '@/components/VideoRecorder.vue';
import Button from '@/components/Button.vue';
import VideoPromptSelection from '@/components/VideoPromptSelection.vue';
import RadioButton from '@/components/RadioButton.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'VideoRecorderModal',
  components: {
    RadioButton,
    VideoPromptSelection,
    VideoRecorder,
    Button,
    ErrorBox,
    CloseIcon,
  },
  props: {
    /**
     * Safari on mobile will handle recordings differently
     */
    isSafariMobile: {
      type: Boolean,
      default: false,
    },
    /**
     * function to set or reset the video recording
     */
    updateVideo: {
      type: Function,
      required: true,
    },
    /**
     * fetches current profile to update profile page after uploading video
     */
    fetchCurrentProfile: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.fetchGroupVideoPrompts(this.$route.params.group).then((res) => {
      if (res.success) {
        this.promptOptions = res.prompts.filter((option) => option.isVisible);
      }
    });
  },
  data() {
    return {
      promptOptions: null,
      prompt: null,
      privacy: 'public',
      file: null,
      errorMessage: null,
      prerecordedSource: null,
      uploadingMessage: null,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions([
      'retrieveS3URL',
      'updateVideoRecorderModal',
      'uploadVideo',
      'fetchVideoWithTaskId',
      'fetchGroupVideoPrompts',
    ]),
    close() {
      const open = false;
      this.updateVideoRecorderModal(open);
    },
    clearPrompt() {
      this.prompt = null;
      this.errorMessage = null;
      this.file = null;
      this.prerecordedSource = null;
    },
    selectPrompt(prompt) {
      this.prompt = prompt;

      // scroll to video recorder if media view
      const windowWidth = document.body.clientWidth;
      if (windowWidth <= 1024) {
        setTimeout(() => {
          const modalContent = document.getElementsByClassName('modal-content')[0];

          if (modalContent) {
            modalContent.scrollIntoView({ behavior: 'smooth' });
            modalContent.scrollTo(0, windowWidth <= 576 ? 100 : 170);
          }
        });
      }
    },
    handlePrerecordedUpload() {
      this.errorMessage = null;
      this.file = null;
      const input = this.$el.querySelector('#prerecordedVideoInput');
      if (input.files && input.files[0]) {
        this.prerecordedSource = URL.createObjectURL(input.files[0]);
        // eslint-disable-next-line prefer-destructuring
        this.updateRecordingFile(input.files[0]);
      }
    },
    clickUploadFile() {
      this.$el.querySelector('#prerecordedVideoInput').click();
    },
    handleUpload() {
      this.uploadingMessage =
        'Stay on this page while your video is uploading (this could take a few seconds)';
      const promptID = this.promptOptions.filter((option) => option.prompt === this.prompt)[0].id;
      this.retrieveS3URL({
        fileName: this.file.name,
        fileType: this.file.type,
        file: this.file,
      }).then((response) => {
        if (response.success) {
          const options = {
            groupSlug: this.$route.params.group,
            s3Key: response.videoLink,
            promptID,
            isVisible: this.privacy === 'public',
          };
          this.uploadVideo(options).then((res) => {
            if (res.success && res.taskId) {
              this.uploadingMessage = 'Almost there! Adding some finishing touches';
              this.pollForVideoLink(res.taskId);
            } else {
              this.prerecordedSource = null;
              this.errorMessage = 'Your video could not be uploaded.';
              this.uploadingMessage = null;
              this.file = null;
            }
          });
        } else {
          this.prerecordedSource = null;
          this.errorMessage = 'Your video could not be uploaded.';
          this.uploadingMessage = null;
          this.file = null;
        }
      });
    },
    fetchVideo(taskId) {
      this.fetchVideoWithTaskId(taskId).then((res) => {
        if (!res.success && res.shouldRefetch) {
          this.pollForVideoLink(taskId);
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line max-len
        // TODO: put this back when we have a better solution for step 1 succeeding and step 2 (watermarking) failing
        // else if (!res.success) {
        //   // if there's no shouldRefetch flag, there was an actual error
        //   this.errorMessage = 'Your video could not be uploaded.';
        //   this.uploadingMessage = null;
        //   this.file = null;
        // }
        else {
          this.uploadingMessage = null;
          this.updateVideoRecorderModal(false);
          this.fetchCurrentProfile();
        }
      });
    },
    pollForVideoLink(taskId) {
      const delay = 2000; // ms
      // let's give the polling a couple seconds in between requests
      setTimeout(() => {
        this.fetchVideo(taskId);
      }, delay);
    },
    updateRecordingFile(file) {
      this.file = file;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  left: 50%;
  transform: translateX(-50%);
  min-height: 65vh;
  max-height: 80vh;
  top: 60px;
}

@media all and (max-width: 1024px) {
  .modal {
    width: calc(100vw - 40px);
  }
}

@media all and (max-width: 576px) {
  .modal {
    top: 90px;
  }
}
</style>
